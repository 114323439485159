<template scoped>
  <CRUD-CreateUpdate
    ref="crud"
    title="Product"
    :toPage="toPage"
    :endpoint="endpoint"
    :parameter="parameter"
    apiPath="Backend/Product"
    :back="back"
    :fields="fields"
  >
  </CRUD-CreateUpdate>
</template>

<script>
export default {
  props: {
    toPage: Function,
    endpoint: String,
    parameter: Object,
  },
  components: {
    "CRUD-CreateUpdate": () => import("cms_infrastructure_web/src/sdk/Admin/CRUD/CRUD-CreateUpdate.vue"),
  },
  data: function () {
    return {
      fields: [
        {
          name: "manufactoryId",
          label: "Manufactory",
          type: "Suggestion",
          apiPath: "Backend/Manufactory",
          valueName: "manufactoryName",
        },
        {
          name: "name",
          label: "Name",
          type: "String",
        },
        {
          name: "regexs",
          label: "Name Regex",
          type: "String[]",
        },
        {
          name: "services",
          label: "Service UUID",
          type: "String[]",
        },
        {
          name: "appearances",
          label: "Appearance",
          type: "String[]",
        }
      ],
    };
  },
  methods: {
    back: function () {
      var $this = this;
      this.toPage("components/Product/Portal-Product.vue", {
        filter: $this.$refs.crud.getFilter(),
      });
    },
  },
};
</script>
